const closestParent = require('./src/utils/closestParent');
const sendGaEvent = require('./src/utils/sendGaEvent');

exports.onRouteUpdate = ({ location }) => {
  sendGaEvent('panel', 'view', true, location.pathname);

  // check if clicked element has 'data-event-label' and send event to GA
  const checkForGa = event => {
    const element = closestParent(event.target, '[data-event-label]', true);
    if (element) {
      const eventLabel = element.getAttribute('data-event-label');
      sendGaEvent(eventLabel);
    }
  };

  document.addEventListener('click', checkForGa, false);
};
